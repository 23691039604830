<template>
  <Head>
    <title>Избранное {{ $titleEnd }}</title>
    <meta property="og:title" :content="'Избранное ' + $titleEnd" />
  </Head>
  <main class="main">
    <div class="container">
      <Breadcrumbs
        :links="[{ name: 'Home', text: 'Главная' }, { text: 'Избранное' }]"
      />
      <div class="plan-favourites">
        <h1 class="h2">Избранное</h1>
        <div v-if="favouritesLength > 0" class="count">
          Всего: {{ favouritesLength }}
          {{
            $filters.declOfNum(favouritesLength, [
              "квартира",
              "квартиры",
              "квартир",
            ])
          }}
        </div>
        <Flats
          v-if="mq.lgPlus && commerceFlats.length > 0"
          showAll
          isCommerce
          :flats="commerceFlats"
        />
        <Flats
          v-if="mq.lgPlus && filteredFlats.length > 0 && flats.length > 0"
          :flats="filteredFlats"
        />
        <FlatsMobile
          v-if="mq.mdMinus && commerceFlats.length > 0"
          showAll
          isCommerce
          :flats="commerceFlats"
        />
        <FlatsMobile
          v-if="mq.mdMinus && filteredFlats.length > 0 && flats.length > 0"
          :flats="filteredFlats"
        />
        <div v-if="favouritesLength == 0" class="not-found text-center">
          <p>В избранном ничего нет.</p>
          <p>
            <router-link class="btn btn-default" :to="{ name: 'PlanSearch' }">
              Подобрать квартиру
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import Breadcrumbs from "@/components/UI/Breadcrumbs.vue";
import Flats from "@/components/FlatsTable.vue";
import FlatsMobile from "@/components/FlatsTableMobile.vue";
import commerceFlats from "@/content/commerce.js";
import unicLots from "@/content/unicLots.js";
import { Head } from "@vueuse/head";
export default {
  components: {
    Head,
    Breadcrumbs,
    Flats,
    FlatsMobile,
  },
  inject: ["favouritesStore", "mq"],
  data() {
    return {
      flats: [],
    };
  },
  computed: {
    favouritesLength() {
      return this.favouritesStore.state.favourites.length;
    },
    additionFlats () {
      return commerceFlats.concat(unicLots)
    },
    commerceFlats () {
      const out = []
      this.favouritesStore.state.favourites.forEach((fav) => {
        this.additionFlats.forEach((flat) => {
          if (fav == flat.id) {
            out.push(flat)
            return flat;
          }
        });
      })
      return out;
    },
    filteredFlats() {
      return this.flats.flatMap((flat) => {
        if (
          this.favouritesStore.state.favourites.indexOf(flat.id) === -1) {
          return [];
        }
        this.$addPlanStub(flat);
        return [flat];
      });
    },
  },
  async mounted() {
    try {
      const response = await axios.get(
        this.$endpoint + "building-objects/" + this.$project + "/properties"
      );
      const data = response.data.data;
      this.flats = data;
    } catch (err) {
      this.$Progress.fail();
    } finally {
      this.$Progress.finish();
    }
  },
};
</script>

<style scoped>
.plan-favourites {
  position: relative;
}

.count {
  position: absolute;
  top: 44px;
  right: 0;
  font-weight: normal;
}

.not-found {
  font-size: 20px;
}
@media (max-width: 1279px) {
  .count {
    top: 39px;
  }
}
@media (max-width: 991px) {
  .count {
    top: 36px;
  }
}
@media (max-width: 767px) {
  .count {
    top: 33px;
  }
}
@media (max-width: 575px) {
  .count {
    position: relative;
    top: auto;
    right: auto;
    margin: -5px 0 13px;
  }
}
</style>
