export default [
  {
    "id": 'commerce47',title: 'Торговое помещение',"uuid": "addition-47",
    "status": "available","number": null,"rent": 151072,"cost": 94243800,"number_on_floor": 10,
    "layout": {
      "code": "k1-f10","type": "flat","room_count": null,"area": 47.21,"living_area": 0,
      "variants": [{"name": "Вариант 1","description": "","rooms": [],"images": []}],
      "plan_image": {"source": require('@/assets/i/Commerce/47-21-flat.jpg'),"image_thumb": require('@/assets/i/Commerce/47-21-flat.jpg')},"render_image": null
    },
    "building": null,"section": null,"floor": {"compass_direction": 0,"number": 1,
      "plan_image": {"source": require('@/assets/i/Commerce/47-21-floor.jpg'),"height": 1314,"width": 2098,"image_thumb": require('@/assets/i/Commerce/47-21-floor.jpg')}
    },
    "properties": {
      props: [
        'Премиальный ЖК',
        'Первая линия улицы Большая Марфинская',
        'Низкий уровень конкуренции в районе',
        'Отдельный вход с улицы',
        'Высота потолков 3 м.',
        'Выделенная мощность 13,2 кВт.',
      ],
      root: 'CommerceFlat',
      genplan: require('@/assets/i/Commerce/47-21-plan.jpg'),link: 'https://trade-estate.ru/sale/uno-starokoptevskiy-/'
    },
    "area_points": null
  },
  // {
  //   "id": 'commerce56',title: 'Торговое помещение',"uuid": "addition-57",
  //   "status": "available","number": null,"rent": 181824,"cost": 94243800,"number_on_floor": 10,
  //   "layout": {
  //     "code": "k1-f10","type": "flat","room_count": null,"area": 57.4,"living_area": 0,
  //     "variants": [{"name": "Вариант 1","description": "","rooms": [],"images": []}],
  //     "plan_image": {"source": require('@/assets/i/Commerce/57-4-flat.jpg'),"image_thumb": require('@/assets/i/Commerce/57-4-flat.jpg')},"render_image": null
  //   },
  //   "building": null,"section": null,"floor": {"compass_direction": 0,"number": 1,
  //     "plan_image": {"source": require('@/assets/i/Commerce/57-4-floor.jpg'),"height": 1314,"width": 2098,"image_thumb": require('@/assets/i/Commerce/57-4-floor.jpg')}
  //   },
  //   "properties": {
  //     props: [
  //       'Премиальный ЖК',
  //       'Первая линия улицы Большая Марфинская',
  //       'Низкий уровень конкуренции в районе',
  //       'Отдельный вход с улицы',
  //       'Высота потолков 3 м.',
  //       'Выделенная мощность 16,8 кВт.',
  //     ],
  //     root: 'CommerceFlat',
  //     genplan: require('@/assets/i/Commerce/57-4-plan.jpg'),link: 'https://trade-estate.ru/sale/uno-starokoptevskiy-/'
  //   },
  //   "area_points": null
  // },
  {
    "id": 'commerce103',title: 'Офисное помещение',"uuid": "addition-103",
    "status": "available","number": null,"rent": 290136,"cost": 94243800,"number_on_floor": 10,
    "layout": {
      "code": "k1-f10","type": "flat","room_count": null,"area": 103.62,"living_area": 0,
      "variants": [{"name": "Вариант 1","description": "","rooms": [],"images": []}],
      "plan_image": {"source": require('@/assets/i/Commerce/103-62-flat.jpg'),"image_thumb": require('@/assets/i/Commerce/103-62-flat.jpg')},"render_image": null
    },
    "building": null,"section": null,"floor": {"compass_direction": 0,"number": 2,
      "plan_image": {"source": require('@/assets/i/Commerce/103-62-floor.jpg'),"height": 1314,"width": 2098,"image_thumb": require('@/assets/i/Commerce/103-62-floor.jpg')}
    },
    "properties": {
      props: [
        'Премиальный ЖК',
        'Свободная планировка',
        'Возможность арендовать весь этаж',
        'Высота потолков 3 м',
        'Выделенная мощность 31,2 кВт.',
        'Лифт',
      ],
      root: 'CommerceFlat',
      genplan: require('@/assets/i/Commerce/103-62-plan.jpg'),link: 'https://trade-estate.ru/sale/uno-starokoptevskiy-/'
    },
    "area_points": null
  },
  {
    "id": 'commerce108',title: 'Офисное помещение',"uuid": "addition-108",
    "status": "available","number": null,"rent": 303380,"cost": 94243800,"number_on_floor": 10,
    "layout": {
      "code": "k1-f10","type": "flat","room_count": null,"area": 108.35,"living_area": 0,
      "variants": [{"name": "Вариант 1","description": "","rooms": [],"images": []}],
      "plan_image": {"source": require('@/assets/i/Commerce/108-35-flat.jpg'),"image_thumb": require('@/assets/i/Commerce/108-35-flat.jpg')},"render_image": null
    },
    "building": null,"section": null,"floor": {"compass_direction": 0,"number": 2,
      "plan_image": {"source": require('@/assets/i/Commerce/108-35-floor.jpg'),"height": 1314,"width": 2098,"image_thumb": require('@/assets/i/Commerce/108-35-floor.jpg')}
    },
    "properties": {
      props: [
        'Премиальный ЖК',
        'Свободная планировка',
        'Возможность арендовать весь этаж',
        'Высота потолков 3 м',
        'Выделенная мощность 32,6 кВт.',
        'Лифт',
      ],
      root: 'CommerceFlat',
      genplan: require('@/assets/i/Commerce/108-35-plan.jpg'),link: 'https://trade-estate.ru/sale/uno-starokoptevskiy-/'
    },
    "area_points": null
  },
  {
    "id": 'commerce114',title: 'Офисное помещение',"uuid": "addition-114",
    "status": "available","number": null,"rent": 319452,"cost": 94243800,"number_on_floor": 10,
    "layout": {
      "code": "k1-f10","type": "flat","room_count": null,"area": 114.09,"living_area": 0,
      "variants": [{"name": "Вариант 1","description": "","rooms": [],"images": []}],
      "plan_image": {"source": require('@/assets/i/Commerce/114-09-flat.jpg'),"image_thumb": require('@/assets/i/Commerce/114-09-flat.jpg')},"render_image": null
    },
    "building": null,"section": null,"floor": {"compass_direction": 0,"number": 2,
      "plan_image": {"source": require('@/assets/i/Commerce/114-09-floor.jpg'),"height": 1314,"width": 2098,"image_thumb": require('@/assets/i/Commerce/114-09-floor.jpg')}
    },
    "properties": {
      props: [
        'Премиальный ЖК',
        'Свободная планировка',
        'Возможность арендовать весь этаж',
        'Высота потолков 3 м',
        'Выделенная мощность 34,2 кВт.',
        'Лифт',
      ],
      root: 'CommerceFlat',
      genplan: require('@/assets/i/Commerce/114-09-plan.jpg'),link: 'https://trade-estate.ru/sale/uno-starokoptevskiy-/'
    },
    "area_points": null
  },
  {
    "id": 'commerce120',title: 'Торговое помещение',"uuid": "addition-120",
    "status": "available","number": null,"rent": 385600,"cost": 94243800,"number_on_floor": 10,
    "layout": {
      "code": "k1-f10","type": "flat","room_count": null,"area": 120.50,"living_area": 0,
      "variants": [{"name": "Вариант 1","description": "","rooms": [],"images": []}],
      "plan_image": {"source": require('@/assets/i/Commerce/120-55-flat.jpg'),"image_thumb": require('@/assets/i/Commerce/120-55-flat.jpg')},"render_image": null
    },
    "building": null,"section": null,"floor": {"compass_direction": 0,"number": 1,
      "plan_image": {"source": require('@/assets/i/Commerce/120-55-floor.jpg'),"height": 1314,"width": 2098,"image_thumb": require('@/assets/i/Commerce/120-55-floor.jpg')}
    },
    "properties": {
      props: [
        'Премиальный ЖК',
        'Низкий уровень конкуренции в районе',
        'Отдельный вход с улицы',
        'Высота потолков 3 м.',
        'Выделенная мощность 46,4 кВт.',
        'Витражное остекление',
      ],
      root: 'CommerceFlat',
      genplan: require('@/assets/i/Commerce/120-55-plan.jpg'),link: 'https://trade-estate.ru/sale/uno-starokoptevskiy-/'
    },
    "area_points": null
  },
  {
    "id": 'commerce146',title: 'Офисное помещение',"uuid": "addition-146",
    "status": "available","number": null,cost:1,"rent": 410536,"number_on_floor": 10,
    "layout": {
      "code": "k1-f10","type": "flat","room_count": null,"area": 146.62,"living_area": 0,
      "variants": [{"name": "Вариант 1","description": "","rooms": [],"images": []}],
      "plan_image": {"source": require('@/assets/i/Commerce/146-62-flat.jpg'),"image_thumb": require('@/assets/i/Commerce/146-62-flat.jpg')},"render_image": null
    },
    "building": null,"section": null,"floor": {"compass_direction": 0,"number": 2,
      "plan_image": {"source": require('@/assets/i/Commerce/146-62-floor.jpg'),"height": 2061,"width": 3000,"image_thumb": require('@/assets/i/Commerce/146-62-floor.jpg')}
    },
    "properties": {
      props: [
        'Премиальный ЖК',
        'Свободная планировка',
        'Возможность арендовать весь этаж',
        'Высота потолков 3 м',
        'Выделенная мощность 44,3 кВт.',
        'Лифт',
      ],
      root: 'CommerceFlat',
      genplan: require('@/assets/i/Commerce/146-62-plan.jpg'),link: 'https://trade-estate.ru/sale/uno-starokoptevskiy-/'
    },
    "area_points": null
  },
  {
    "id": 'commerce541',title: 'Торговое помещение',"uuid": "addition-541",
    "status": "available","number": null,cost:1,"rent": 1406990,"number_on_floor": 10,
    "layout": {
      "code": "k1-f10","type": "flat","room_count": null,"area": 541.15,"living_area": 0,
      "variants": [{"name": "Вариант 1","description": "","rooms": [],"images": []}],
      "plan_image": {"source": require('@/assets/i/Commerce/541-15-flat.jpg'),"image_thumb": require('@/assets/i/Commerce/541-15-flat.jpg')},"render_image": null
    },
    "building": null,"section": null,"floor": {"compass_direction": 0,"number": 1,
      "plan_image": {"source": require('@/assets/i/Commerce/541-15-floor.jpg'),"height": 1314,"width": 2098,"image_thumb": require('@/assets/i/Commerce/541-15-floor.jpg')}
    },
    "properties": {
      props: [
        'Премиальный ЖК',
        'Первая линия улицы Ботаническая',
        'Низкий уровень конкуренции в районе',
        'Отдельный вход с улицы',
        'Высота потолков 4 м',
        'Торговый зал 389 кв. м.',
        'Выделенная мощность 162,3 кВт.',
        'Витражное остекление',
        'Наличие зоны разгрузки',
      ],
      root: 'CommerceFlat',
      genplan: require('@/assets/i/Commerce/541-15-plan.jpg'),link: 'https://trade-estate.ru/sale/uno-starokoptevskiy-/'
    },
    "area_points": null
  },
]
